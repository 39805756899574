import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import _, { set } from "lodash";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Trash } from 'react-bootstrap-icons';
import inflationData from './inflation_data.json'
import * as d3 from "d3";
import ToolTip from "./ToolTip";
//import Pako from "pako";
//var data = require('json!./../public/inflation_data.json');

function compress(string, encoding) {
  const byteArray = new TextEncoder().encode(string);
  const cs = new CompressionStream(encoding);
  const writer = cs.writable.getWriter();
  writer.write(byteArray);
  writer.close();
  return new Response(cs.readable).arrayBuffer();
}

function decompress(byteArray, encoding) {
  const cs = new DecompressionStream(encoding);
  const writer = cs.writable.getWriter();
  writer.write(byteArray);
  writer.close();
  return new Response(cs.readable).arrayBuffer().then(function (arrayBuffer) {
    return new TextDecoder().decode(arrayBuffer);
  });
}

function _arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function _base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const createGraph = async (data) => {


  data.forEach((d) => {
    if (d.date.split) {
      const split = d.date.split('-')
      d.date = new Date(split[0], split[1]);
    }

  });

  const w = document.getElementById('graph').getBoundingClientRect().width - 30

  // set the dimensions and margins of the graph
  var margin = { top: 20, right: 35, bottom: 50, left: 80 },
    width = w - margin.left - margin.right,
    height = 300 - margin.top - margin.bottom;

  // append the svg object to the body of the page
  d3.select("#graph").selectAll("*").remove();


  var svg = d3.select("#graph")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

  // add X axis and Y axis
  var x = d3.scaleLinear().range([0, width]);
  var y = d3.scaleLinear().range([height, 0]);

  var yRight = d3.scaleLinear().range([height, 0]);
  yRight.domain([0, d3.max(data, (d) => { return d.pace; })]);


  x.domain(d3.extent(data, (d) => { return d.date; }));
  y.domain([d3.min(data, (d) => { return Math.min(d.value, d.amount); }), d3.max(data, (d) => { return Math.max(d.value, d.amount); })]);

  svg.append("g")
    .attr("transform", `translate(0, ${height})`)
    .call(d3.axisBottom(x).ticks((width < 400 ? 5 : 10)).tickFormat(d3.timeFormat("%m-%y")));

  svg.append("g")
    .call(d3.axisLeft(y).ticks(10).tickFormat(d3.format("$,.2f")));

  

  svg.append("g")
    .attr("class", "grid")
    .attr("transform", `translate(0, ${height})`)
    .call(d3.axisBottom(x)
      .ticks(10)
      .tickSize(-height)
      .tickFormat("")
    );

  // Append gridlines to the Y axis
  svg.append("g")
    .attr("class", "grid")
    .call(d3.axisLeft(y)
      .tickSize(-width)
      .tickFormat("")
    );

  function crosses(d1, d2) {
    return (d1.value > d1.amount && d2.value < d2.amount)
    || (d1.value < d1.amount && d2.value > d2.amount);
  }

  // Segment the data
  let segments = [];
  let currentSegment = [data[0]];
  
  for (let i = 1; i < data.length; i++) {
      if (crosses(data[i - 1], data[i])) {
          currentSegment.push(data[i]);  // Include the crossing point in the current segment
          segments.push(currentSegment); // End the current segment at the crossing point
          currentSegment = [data[i]];    // Start a new segment with the crossing point
      } else {
          currentSegment.push(data[i]);
      }
  }
  
  segments.push(currentSegment); // Add the last segment

  // Define two area generators
  var areaAbove = d3.area()
    .x((d) => x(d.date))
    .y0((d) => y(d.amount))
    .y1((d) => y(d.value))
     //.curve(d3.curveStep); 


  var areaBelow = d3.area()
    .x((d) => x(d.date))
    .y0((d) => y(d.value))
    .y1((d) => y(d.amount))
   // .curve(d3.curveStep); 


  // Draw areas based on condition
  segments.forEach(segment => {
    // Determine if the segment is above or below by checking the first data point
    const isAbove = segment[0].value > segment[0].amount;
  
    svg.append("path")
      .data([segment])
      .attr("class", "area")
      .attr("fill", isAbove ? "rgba(255, 0, 0, 0.5)" : "rgba(0, 0, 255, 0.5)")
      .attr("d", isAbove ? areaAbove : areaBelow);
  });
  
  var incomeLine = d3.line()
    .x((d) => { return x(d.date); })
    .y((d) => { return y(d.amount); })
    //.curve(d3.curveStep);


  svg.append("path")
    .data([data])
    .attr("class", "line")
    .attr("fill", "none")
    .attr("stroke", "steelblue") // Different color for the new line
    .attr("stroke-width", 5.5)
    .attr("d", incomeLine)


  // add the Line
  var valueLine = d3.line()
    .x((d) => { return x(d.date); })
    .y((d) => { return y(d.value); })
    


  svg.append("path")
    .data([data])
    .attr("class", "line")
    .attr("fill", "none")
    .attr("stroke", "red")
    .attr("stroke-width", 5.5)
    .attr("d", valueLine);


  svg.append("text")
    .attr("x", x(data[data.length - 1].date) + 5) // 5 pixels to the right of the last point
    .attr("y", y(data[data.length - 1].amount))
    .attr("dy", ".35em")
    .style("fill", "steelblue") // Match the line color
    .text("Income");

  // Label for the value line
  svg.append("text")
    .attr("x", x(data[data.length - 1].date) + 5) // 5 pixels to the right of the last point
    .attr("y", y(data[data.length - 1].value))
    .attr("dy", ".35em")
    .style("fill", "red") // Match the line color
    .text("Inflation");

  // Add Legend
  const legend = svg.append("g")
    .attr("class", "legend")
    .attr("transform", `translate(10, 10)`); // Position legend on the left, adjust y as needed

  // Red Area Legend
  legend.append("rect")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", 10)
    .attr("height", 10)
    .style("fill", "rgba(0, 0, 255, 0.5)"); // Red, semi-transparent

  legend.append("text")
    .attr("x", 20)
    .attr("y", 10)
    .text("Earning more than inflation")
    .style("font-size", "10px");

  // Blue Area Legend
  legend.append("rect")
    .attr("x", 0)
    .attr("y", 20)
    .attr("width", 10)
    .attr("height", 10)
    .style("fill", "rgba(255, 0, 0, 0.5)"); // Blue, semi-transparent

  legend.append("text")
    .attr("x", 20)
    .attr("y", 30)
    .text("Earning less than inflation")
    .style("font-size", "10px");

}

const examples = [
  {
    name: "Average American Income (Above inflation)",
    useSalary: true,
    raiseHistory: [
      {
        "startMonth": 1,
        "startYear": 2022,
        "endMonth": 12,
        "endYear": 2023,
        "annualAmount": 74580,
      },
      {
        "endMonth": 1,
        "endYear": 2022,
        "annualAmount": 76330,
        "startMonth": 1,
        "startYear": 2021,
      },
      {
        "endMonth": 1,
        "endYear": 2021,
        "annualAmount": 68010,
        "startMonth": 1,
        "startYear": 2020,
      },
      {
        "endMonth": 1,
        "endYear": 2020,
        "annualAmount": 68703,
        "startMonth": 1,
        "startYear": 2019,
      },
      {
        "endMonth": 1,
        "endYear": 2019,
        "annualAmount": 63179,
        "startMonth": 1,
        "startYear": 2018,
      },
      {
        "endMonth": 1,
        "endYear": 2018,
        "annualAmount": 61136,
        "startYear": 2017,
        "startMonth": 1,
      },
      {
        "endMonth": 1,
        "endYear": 2017,
        "annualAmount": 61372,
        "startYear": 2016,
        "startMonth": 1,
      },
      {
        "endMonth": 1,
        "endYear": 2016,
        "annualAmount": 59039,
        "startYear": 2015,
        "startMonth": 1,
      },
      {
        "endMonth": 1,
        "endYear": 2015,
        "annualAmount": 56516,
        "startYear": 2014,
        "startMonth": 1,
      },
      {
        "endMonth": 1,
        "endYear": 2014,
        "annualAmount": 53657,
        "startMonth": 1,
        "startYear": 2013,
      },
      {
        "endMonth": 1,
        "endYear": 2013,
        "annualAmount": 53585,
        "startMonth": 1,
        "startYear": 2012,
      }
    ]
  },
  {
    name: "Average American Teacher (Below inflation)",
    useSalary: true,
    raiseHistory: [
      {
        "startMonth": 1,
        "startYear": 2023,
        "endMonth": 11,
        "endYear": 2023,
        "annualAmount": 67000,
      },
      {
        "endMonth": 1,
        "endYear": 2023,
        "annualAmount": 66300,
        "startMonth": 1,
        "startYear": 2022,
      },
      {
        "endMonth": 1,
        "endYear": 2022,
        "annualAmount": 65290,
        "startYear": 2021,
        "startMonth": 1,
      },
      {
        "endMonth": 1,
        "endYear": 2021,
        "annualAmount": 64172,
        "startMonth": 1,
        "startYear": 2020,
      },
      {
        "endMonth": 1,
        "endYear": 2020,
        "annualAmount": 62271,
        "startMonth": 1,
        "startYear": 2019,
      },
      {
        "endMonth": 1,
        "endYear": 2019,
        "annualAmount": 60784,
        "startMonth": 1,
        "startYear": 2018,
      },
      {
        "endMonth": 1,
        "endYear": 2018,
        "annualAmount": 59752,
        "startMonth": 1,
        "startYear": 2017,
      },
      {
        "endMonth": 1,
        "endYear": 2017,
        "annualAmount": 58504,
        "startMonth": 1,
        "startYear": 2016,
      },
      {
        "endMonth": 1,
        "endYear": 2016,
        "annualAmount": 57758,
        "startMonth": 1,
        "startYear": 2015,
      },
      {
        "endMonth": 1,
        "endYear": 2015,
        "annualAmount": 57073,
        "startMonth": 1,
        "startYear": 2014,
      }
    ]
  },
  {
    name: "Warehouse Worker (Above inflation)",
    useSalary: false,
    raiseHistory: [
      {
        "startMonth": 1,
        "startYear": 2023,
        "endMonth": 11,
        "endYear": 2023,
        "annualAmount": 18,
      },
      {
        "endMonth": 1,
        "endYear": 2023,
        "annualAmount": 17,
        "startMonth": 1,
        "startYear": 2022,
      },
      {
        "endMonth": 1,
        "endYear": 2022,
        "annualAmount": 16,
        "startMonth": 1,
        "startYear": 2021,
      },
      {
        "endMonth": 1,
        "endYear": 2021,
        "annualAmount": 15,
        "startMonth": 1,
        "startYear": 2020,
      }
    ]
  },
  {
    name: "Software Engineer (Above inflation)",
    useSalary: true,
    raiseHistory: [
      {
        "startMonth": 1,
        "startYear": 2023,
        "endMonth": 11,
        "endYear": 2023,
        "annualAmount": 240000,
      },
      {
        "endMonth": 1,
        "endYear": 2023,
        "annualAmount": 215000,
        "startMonth": 1,
        "startYear": 2022,
      },
      {
        "endMonth": 1,
        "endYear": 2022,
        "annualAmount": 190000,
        "startYear": 2021,
        "startMonth": 1,
      }
    ]
  }
]

// function getCumulativeInflationRate(startDate, endDate) {
//   let startYear = startDate.getFullYear();
//   let startMonth = startDate.getMonth() + 1; // getMonth() returns 0-11
//   let endYear = endDate.getFullYear();
//   let endMonth = endDate.getMonth() + 1; // getMonth() returns 0-11
//   let cumulativeRate = 1.0;

//   for (let year = startYear; year <= endYear; year++) {
//       for (let month = 1; month <= 12; month++) {
//           if ((year === startYear && month < startMonth) || (year === endYear && month > endMonth)) {
//               continue;
//           }
//           let key = `${year}-${month.toString().padStart(2, '0')}`;
//           if (inflationData[key]) {
//               cumulativeRate *= (1 + (inflationData[key] / 100));
//           }
//       }
//   }

//   return cumulativeRate;
// }

function getCumulativeInflationRate(startDate, endDate) {
  let cumulativeRate = 1.0;
  let start = new Date(startDate);
  let end = new Date(endDate);

  for (let d = start; d <= end; d.setMonth(d.getMonth() + 1)) {
    let key = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}`;
    if (inflationData[key]) {
      cumulativeRate *= (1 + inflationData[key] / 12 / 100);
    }
  }

  return cumulativeRate;
}

function adjustForInflation(amount, date) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11
  const endDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}`;

  const inflationRate = getCumulativeInflationRate(date, endDate);
  return amount * inflationRate;
}


function App() {

  const widthRef = useRef(null);


  const [useSalary, setUseSalary] = useState(false);
  const [yearsWorked, setYearsWorked] = useState(0);
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [monthsSinceLastRaise, setMonthsSinceLastRaise] = useState(0);
  const [zipCode, setZipcode] = useState(null);
  const [email, setEmail] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [results, setResults] = useState(null);
  const [raiseHistory, setRaiseHistory] = useState([{
    startMonth: 1,
    startYear: 2023,
    endMonth: 12,
    endYear: 2023,
    annualAmount: 20,
  }]);
  const [errors, setErrors] = useState({});
  const [targetGrowth, setTargetGrowth] = useState(3);
  const [isOpen, setIsOpen] = useState(false);
  const [shareText, setShareText] = useState("Share Graph Link");
  const [holderClass, setHolderClass] = useState("")
  const [successes, setSuccesses] = useState([""])
  const [showSubmitButton, setShowSubmitButton] = useState(true)
  const [targetRaise, setTargetRaise] = useState(null)
  const [name, setName] = useState(null)

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (widthRef.current) {
      document.documentElement.style.setProperty('--shift-distance', `${widthRef.current.offsetWidth}px`);
    }
  })

  useEffect(() => {
    const dataFromUrl = new URLSearchParams(window.location.search).get('d')
    if (dataFromUrl) {

      // const compressed = decompress(_base64ToArrayBuffer(dataFromUrl), 'gzip')
      // debugger
      decompress(_base64ToArrayBuffer(dataFromUrl), 'gzip').then((data) => {
        const d = JSON.parse(data)
        setUseSalary(d.useSalary)
        setRaiseHistory(d.raiseHistory)
      })
    }
  }, []);

  useEffect(() => {
    onSubmit()

  }, [raiseHistory, useSalary]);

  useEffect(() => {
    if (results) {
      createGraph(results.graphData, targetGrowth)
    }
  }, [results]);

  const historyErrors = () => {
    const errors = {}
    raiseHistory.forEach((raise, i) => {
      const rowErrors = []
      if (!raise.startMonth || !raise.startYear || !raise.endMonth || !raise.endYear) {
        rowErrors.push("All dates must be filled out")
      }
      const startDate = new Date(raise.startYear, raise.startMonth - 1);  // JS months are 0-indexed
      const endDate = new Date(raise.endYear, raise.endMonth - 1);


      if (startDate > endDate) {
        rowErrors.push("Start date must be before end date")
      }

      if (raise.annualAmount <= 0) {
        rowErrors.push("Amount must be greater than 0")
      }

      if (i > 0) {
        const previousRaise = raiseHistory[i - 1]
        const previousStartDate = new Date(previousRaise.startYear, previousRaise.startMonth - 1);
        if (endDate < previousStartDate) {
          rowErrors.push("End date must be before the previous start date")
        }
      }

      if (rowErrors.length > 0) {
        errors[i] = rowErrors
      }


    })

    return errors;
  }


  const onSubmit = () => {


    setShareText("Share Anonymous Salary Graph Link")
    setErrors(historyErrors())

    if (Object.keys(historyErrors()).length > 0 || raiseHistory.length == 0) {
      //createGraph([])
      return
    }


    //console.log("raiseHistory", raiseHistory)
    let combinedAmountData = {}
    raiseHistory.map((raise) => {
      raise.start = new Date(raise.startYear, raise.startMonth - 1);  // JS months are 0-indexed
      raise.end = new Date(raise.endYear, raise.endMonth - 1);
      // raise.startMonth = parseInt(raise.startMonth)
      // raise.startYear = parseInt(raise.startYear)
      // raise.endMonth = parseInt(raise.endMonth)
      // raise.endYear = parseInt(raise.endYear)
      raise.annualAmount = parseFloat(raise.annualAmount)
      const inflationData = getInflationDataInRange(raise.start, raise.end)
      const inflationIncreaseRate = getCumulativeInflationRate(raise.start, raise.end) - 1
      //console.log("dsadas", increaseRate, increaseRateTest)
      raise.inflationIncreaseRate = inflationIncreaseRate
      raise.increaseAmount = raise.annualAmount * inflationIncreaseRate
      // raise.newAmount = raise.annualAmount + raise.increaseAmount
      // //console.log("dsada", raise.newAmount )
      Object.keys(inflationData).forEach((key) => {
        combinedAmountData[key] = raise.annualAmount
      });

    })

    const combinedInflationData =   getInflationDataInRange(raiseHistory[raiseHistory.length - 1].start, raiseHistory[0].end)

    let amount = raiseHistory[raiseHistory.length - 1].annualAmount
    let startDate = new Date(raiseHistory[raiseHistory.length - 1].startYear, raiseHistory[raiseHistory.length - 1].startMonth - 1);  // JS months are 0-indexed
    //let today = new Date();


    let graphData = Object.entries(combinedInflationData).map(([key, value]) => {
      const date = new Date(key.split('-')[0], key.split('-')[1] - 1)

      return {
        date: key,
        value: getCumulativeInflationRate(startDate, date) * amount,
        amount: combinedAmountData[key]
      }
    })

    graphData = _.sortBy(graphData, 'date')

    let totalInflationAdjustedIncomeLost = graphData.reduce((accumulator, row) => {
      // TODO adjust for inflation
      const startDate = row.date
      const endDate = new Date()

      const adjustedAmount = getCumulativeInflationRate(startDate, endDate) * (parseFloat(row.amount) - parseFloat(row.value));
      if (useSalary) {
        return accumulator + (adjustedAmount / 12)
      } else {
        return accumulator + (adjustedAmount * (40 * 4))
      }
    }, 0)



    const differenceBetweenInflationAndRaise = graphData.reduce((a, b) => a + (b.value - b.amount), 0) / 12
    const neededRaise = graphData[graphData.length - 1].value - graphData[graphData.length - 1].amount
    const percentAmount = ((graphData[graphData.length - 1].value - graphData[graphData.length - 1].amount) / graphData[graphData.length - 1].value * 100)

    let raiseText = "Based on your income history you definitely are eligible for a raise."

    if (-percentAmount < 0) {
      raiseText = "You are not keeping up with inflation, you need a raise!"
    }

    if (-percentAmount > 5) {
      raiseText = "You are keeping up with inflation, but you should still ask for a raise."
    }
    if (-percentAmount > 25) {
      raiseText = "Your raises are keeping up with inflation, but you should still ask for a raise."
    }

    setTargetRaise(graphData[graphData.length - 1].value.toFixed(2))


    setResults({
      startingAmount: raiseHistory[raiseHistory.length - 1].annualAmount,
      targetAmount: graphData[graphData.length - 1].value,
      currentAmount: graphData[graphData.length - 1].amount,
      percentAmount: percentAmount.toFixed(2),
      neededRaise,
      differenceBetweenInflationAndRaise,
      combinedAmountData: combinedAmountData,
      inflationData: combinedInflationData,
      graphData: graphData,
      totalInflationAdjustedIncomeLost,
      raiseText
    });
  }

  function getInflationDataInRange(startDate, endDate) {
    let results = {};
    // let startDate = new Date(startYear, startMonth - 1);  // JS months are 0-indexed
    // let endDate = new Date(endYear, endMonth - 1);

    for (const [key, value] of Object.entries(inflationData)) {
      let [year, month] = key.split('-').map(Number);
      let date = new Date(year, month - 1);

      if (date >= startDate && date <= endDate) {
        results[key] = value;
      }
    }

    return results;
  }

  // const getInflationRate = (startMonth, startYear, endMonth, endYear) => {
  //   const data = getInflationDataInRange(startMonth, startYear, endMonth, endYear)
  //   return Object.values(data).reduce((a, b) => a + (b / 100 / 12), 0)

  // }

  const onAddRaise = (e) => {
    e.preventDefault();
    const data = {
      endMonth: raiseHistory[raiseHistory.length - 1].startMonth,
      endYear: raiseHistory[raiseHistory.length - 1].startYear,
      annualAmount: 0,
    };
    raiseHistory.push(data)

    setRaiseHistory(_.cloneDeep(raiseHistory))
  }

  const validateZipCode = (zipcode) => {
    const regex = /^[0-9]{5}(?:-[0-9]{4})?$/;
    return regex.test(zipcode);
  }

  const validZipcode = validateZipCode(zipCode)

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  let years = []
  for (let i = 2024; i > 2000; i--) {
    years.push(i)
  }

  const clickAsForARaise = (e) => {
    window.scrollTo(0, 0);
    setHolderClass("scaleAndShiftRight")

  }



  const onExampleChange = (e) => {
    const example = examples[e.target.value]
    if (!example) {
      setUseSalary(false)
      setRaiseHistory([{
        startMonth: 1,
        startYear: 2023,
        endMonth: 12,
        endYear: 2023,
        annualAmount: 20,
      }])
      return
    }
    setUseSalary(example.useSalary)
    setRaiseHistory(example.raiseHistory)
  }

  const onCopyShareLink = async (e) => {
    e.preventDefault();
    const url = new URL('https://askforapayraise.com');
    const d = {
      raiseHistory,
      useSalary
    }
    const compressed = _arrayBufferToBase64(await compress(JSON.stringify(d), 'gzip'))

    url.searchParams.set('d', compressed);
    //console.log(compressed)
    navigator.clipboard.writeText(url.href).then(() => {
      setShareText("Copied!")
    }).catch(err => {
      console.error('Error in copying text: ', err);
    });

    //window.location.href = url.href;
  }

  const validEmail = email.length > 0 && email.includes('@') && email.includes('.')

  const clickGetPacket = (e) => {
    setFormSubmitted(true);
    if (validEmail && validZipcode && name && company && title) {

      const payload = {
        email,
        zipCode,
        raiseHistory,
        useSalary,
        title,
        company,
        successes,
        results,
        targetRaise,
        name,
        svg: document.getElementById('graph').outerHTML
      }

      console.log("payload", payload)
      setShowSubmitButton(false)
      fetch('https://hysvkwwn4mtj7dbzpd55jpbmbi0mpsgb.lambda-url.us-east-1.on.aws/', {
        method: 'POST',
        body: JSON.stringify(payload)
      })
    }
  }

  const totalInflationGainText = (() => {
    if (!results) {
      return null
    }
    if (results.totalInflationAdjustedIncomeLost < 0) {
      return <p>You have lost {USDollar.format(results.totalInflationAdjustedIncomeLost)} to inflation.</p>
    } else {
      return <p>You have earned {USDollar.format(Math.abs(results.totalInflationAdjustedIncomeLost))} over inflation since {raiseHistory[raiseHistory.length-1].startYear}.</p>
    }
  })();


  return (
    <div className="App" style={{ backgroundColor: '#5caeff', paddingTop: '1.5rem' }}>
      <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top ">
        <div class="container-fluid">
          <a class="navbar-brand" href="/">

            < h4>Ask For A Pay Raise 💵</h4>
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={toggle}>
            <span class="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="/">Raise Calculator</a>
              </li>

              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="/articles">Articles</a>
              </li>

              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="/articles/about">About</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <main className="container container-sm App mt-10">
        <div className="row mb-3">
          <div className="col-12 card mt-5">
            <div className="row">



            </div>
            <div id="holder" ref={widthRef} >
              <div className={`window `} >
                <div className={`stepHolder  ${holderClass}`}>
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                      <form className="form mb-5  p-lg-5 p-sm-1 p-md-1 " >

                        <h3>Current Employer Income History</h3>



                        <div className="row mb-1 mt-3">
                        <div class="col-4-lg  col-4" >
                          <label className="col-4-lg text-start col-form-label col-12">
                            Load an example:
                          </label>
                          </div>
                          <div class="col-6-lg  col-6" >
                            <select onChange={onExampleChange} className="form-select " style={{height: 50}}>
                              <option>Enter your income history</option>
                              {examples.map((example, i) => {
                                return <option key={i} value={i}>{example.name}</option>
                              })}
                            </select>

                          </div>
                        </div>

                        <hr />

                        <div className="row mb-1">
                          <label className="col-7 text-start col-form-label">
                            Employment type: <strong>{useSalary ? "Salary" : "Hourly Rate (40 hour)"}</strong>
                          </label>
                          <div class="col-5 form-check form-switch">
                            <label class="form-check-label" for="flexSwitchCheckDefault">Annual Salary</label>
                            <input class="form-check-input" style={{ marginLeft: 0 }} type="checkbox" role="switch" checked={useSalary} id="flexSwitchCheckDefault" onChange={(e) => setUseSalary(e.target.checked)} />

                          </div>
                        </div>

                        <div className="row mb-1">
                          <label className="col-3 text-start col-form-label">
                            From
                          </label>
                          <label className="col-3 text-start col-form-label">
                            To
                          </label>
                          <label className="col-4 text-start col-form-label">
                            {useSalary ? "Salary + bonus" : "Hourly Rate"}
                          </label>
                        </div>

                        {
                          raiseHistory.map((raise, i) => {
                            const rowStyle = _.some(errors[i]) ? { border: '1px solid red', borderRadius: 5, paddingBottom: 5, paddingTop: 5 } : {}
                            return (<div className="row mb-1" key={i} style={rowStyle}>


                              {
                                errors[i] &&
                                <div>
                                  {
                                    errors[i].map((error, i) => {
                                      return <div className="text-start " style={{ color: 'red' }} key={i}>
                                        {error}
                                      </div>
                                    })
                                  }
                                </div>

                              }

                              <div className='col-6 col-sm-6 col-md-6 text-start income'>
                                <div className="row mb-2">
                                  <div className="col-6" >

                                    <select className="form-select" value={raise.startMonth} onChange={(e) => {
                                      raise.startMonth = e.target.value
                                      setRaiseHistory(_.cloneDeep(raiseHistory))
                                    }}>
                                      <option></option>
                                      {months.map((month, i) => {
                                        return <option key={i} value={i + 1}>{month}</option>
                                      })}
                                    </select>
                                    <select  className="form-select"  value={raise.startYear} onChange={(e) => {
                                      raise.startYear = e.target.value
                                      setRaiseHistory(_.cloneDeep(raiseHistory))
                                    }}>
                                      <option></option>
                                      {years.map((year, i) => {
                                        return <option key={i} value={year}>{year}</option>
                                      })}
                                    </select>
                                  </div>

                                  <div className="col-6" >

                                    <select  className="form-select"  value={raise.endMonth} onChange={(e) => {
                                      raise.endMonth = e.target.value
                                      setRaiseHistory(_.cloneDeep(raiseHistory))
                                    }}>
                                      <option></option>
                                      {months.map((month, i) => {
                                        return <option key={i} value={i + 1}>{month}</option>
                                      })}
                                    </select>
                                    <select  className="form-select"  value={raise.endYear} onChange={(e) => {
                                      raise.endYear = e.target.value
                                      setRaiseHistory(_.cloneDeep(raiseHistory))
                                    }}>
                                      <option></option>
                                      {years.map((year, i) => {
                                        return <option key={i} value={year}>{year}</option>
                                      })}
                                    </select>

                                  </div>

                                </div>

                              </div>

                              <div className="col-6 col-sm-6 col-md-6 text-start ">
                                <div className="row">
                                  <div className="col-10 text-end">
                                    <div class="input-group has-validation">
                                      <span class="input-group-text" id="inputGroupPrepend">$</span>
                                      <input type="text" class={`form-control ${false && "is-invalid"}`} id="validationCustomUsername" value={raise.annualAmount} aria-describedby="inputGroupPrepend" required="" onChange={(e) => {
                                        raise.annualAmount = e.target.value
                                        setRaiseHistory(_.cloneDeep(raiseHistory))
                                      }} />
                                      <div class="invalid-feedback">
                                        Amount not valid
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-2" style={{paddingLeft: 0}}>
                                    {i == raiseHistory.length - 1 && <Trash style={{ cursor: 'pointer' }} onClick={() => {
                                      raiseHistory.pop()
                                      setRaiseHistory(_.cloneDeep(raiseHistory))
                                    }} />}

                                  </div>
                                </div>


                              </div>
                            </div>);
                          })
                        }

                        <div className="row mb-1">
                          <label className="col-7 text-start col-form-label">
                          </label>
                          <div class="col-5 form-check form-switch mt-2">
                            <button className="btn btn-primary btn-sm" style={{ width: 100 }} onClick={onAddRaise}>Add Raise</button>
                          </div>
                        </div>

                      </form>
                    </div>

                    <div className="col-lg-6 col-md-12 col-12">
                      <div className=" mb-5 mt-5" >
                        <div className="row">
                          {
                            results ? <div >
                              <h2>Does your income keep up with inflation?</h2>
                              <div className="col-12 order-2">
                                <svg id="graph" style={{ width: "100%" }}></svg>

                              </div>
                              <div className="col-12 order-1">
                                {
                                  results.neededRaise > 0 ?
                                    <>
                                      <h5 style={{ color: "red" }}>💸You are not keeping up with inflation💸</h5>
                                      <p>The inflation adjusted income you should be making today is</p>
                                      <h1><strong>{USDollar.format(results.targetAmount)}</strong> <span className="fs-6">{useSalary ? 'per year' : 'per hour'}</span> </h1>

                                      <p>That's a <strong>{results.percentAmount}%</strong> increase from your current {useSalary ? 'salary' : 'hourly rate'}</p>
                                      <p>Ask for a raise of <strong>{USDollar.format(results.neededRaise)}</strong> to just keep up with inflation.</p>
                                      {totalInflationGainText}
                                    </> :
                                    <>
                                      <h5 style={{ color: "green" }}>💰You are keeping up with inflation💰</h5>
                                      <p>You are making <strong>{results.percentAmount * -1}%</strong> more than from you inflation-adjusted beginning {useSalary ? 'salary' : 'hourly rate'}</p>
                                      {totalInflationGainText}
                                    </>
                                }
                                {window.isSecureContext && <button className="btn btn-secondary" onClick={onCopyShareLink}>{shareText}</button>}
                              </div>
                            </div>
                              :
                              <div>
                                <h2>Fill out the form</h2>
                                <svg id="graph" style={{ width: "100%" }}></svg>
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mb-5">

                        <h3>{results?.raiseText}</h3>
                        <button className="btn btn-warning" onClick={clickAsForARaise} >Learn how to ask for a raise &gt;</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`stepHolder ${holderClass}`}>
                  <div className="row mt-5">
                    <div className="col-lg-2 col-md-6 col-6">
                      <button className="btn btn-warning" onClick={() => setHolderClass("scaleAndShiftLeft")} >&lt; Back to income </button>
                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                      <h3>Personalized Information</h3>
                      <p className="text-muted">
                        Provide us with some additional information to help us prepare your personalized raise guide.
                      </p>
                    </div>
                    <div className="col-lg-2 col-md-4 col-4"></div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-6 col-md-12 col-12 mb-5  p-lg-5 p-sm-1 p-md-1 ">
                      {/* <h3>Raise Details</h3>

                      <div className="row mb-1">
                        <p className="text-muted">
                          Adjust your target raise amount.
                        </p>
                        <hr />
                      </div>

                      <div className="row mb-1">
                        <label className="col-7 text-start col-form-label">
                          Target {useSalary ? "salary" : "hourly rate"}
                        </label>
                        <div className="col-5">
                          <div class="input-group has-validation mb-5">
                            <span class="input-group-text" id="inputGroupPrepend">$</span>
                            <input type="number" class={`form-control`} value={targetRaise} aria-describedby="inputGroupPrepend" required="" onChange={(e) => {
                              setTargetRaise(e.target.value)
                            }
                            } />
                            <div class="invalid-feedback">
                              Amount not valid
                            </div>
                          </div>


                        </div>
                      </div> */}

                      <h3>Role Information</h3>

                      <div className="row mb-1">
                        <p className="text-muted">
                          Provide some information about your role to make a personalized raise pitch.
                        </p>
                        <hr />
                      </div>

                      <div className="row mb-1">
                        <label className="col-7 text-start col-form-label">
                          Job Title <ToolTip text={"We'll use this to find the average income for your role"} />
                        </label>
                        <div className="col-5">
                          <input
                            type="text"
                            class={`form-control ${!title && formSubmitted && "is-invalid"}`}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                          <div class="invalid-feedback ">
                            Job title is required
                          </div>
                        </div>
                      </div>

                      <div className="row mb-1">
                        <label className="col-7 text-start col-form-label">
                          Your Name <ToolTip text={"We'll use this to add to your raise presentation"} />
                        </label>
                        <div className="col-5">
                          <input
                            type="text"
                            class={`form-control ${!name && formSubmitted && "is-invalid"}`}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          <div class="invalid-feedback ">
                            Name is required
                          </div>
                        </div>
                      </div>

                      <div className="row mb-1">
                        <label className="col-7 text-start col-form-label">
                          Company Name <ToolTip text={"We'll use this to find the average income for your company"} />
                        </label>
                        <div className="col-5">
                          <input
                            class={`form-control ${!company && formSubmitted && "is-invalid"}`}
                            type="text"
                          
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                          />
                           <div class="invalid-feedback ">
                            Company name is required
                            </div>
                       
                        </div>
                      </div>

                      <div className="row mb-1">
                        <label className="col-7 text-start col-form-label">
                          Zip Code <ToolTip text={"We'll use the to find the average income in your area"} />
                        </label>
                        <div className="col-5">
                          <div class="input-group has-validation">

                            <input type="number" class={`form-control ${!validZipcode && formSubmitted && "is-invalid"}`} id="validationCustomUsername" aria-describedby="inputGroupPrepend" required="" value={zipCode} onChange={(e) => setZipcode(e.target.value)} />
                            <div class="invalid-feedback ">
                              Zip Code is not valid
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-1">
                        <label className="col-7 text-start col-form-label">
                          Email address <ToolTip text={"We'll send your raise guide to your email"} />
                        </label>
                        <div className="col-5">
                          <div class="input-group has-validation">

                            <input type="text" class={`form-control ${!validEmail && formSubmitted && "is-invalid"}`} id="validationCustomUsername" aria-describedby="inputGroupPrepend" required="" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <div class="invalid-feedback ">
                              Email is not valid
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>

                    <div className="col-lg-6 col-md-12 col-12 mb-5  p-lg-5 p-sm-1 p-md-1 ">
                      <h3>Key accomplishments</h3>

                      <div className="row mb-1">
                        <p className="text-muted">
                          Provide some examples of your key accomplishments at your current role since your last raise.
                        </p>
                        <hr />
                      </div>
                      {
                        successes.map((success, i) => {
                          return <div className="row mb-1" key={i}>
                            <div className="col-12" style={{ textAlign: 'left' }}>Accomplishment #{i + 1}</div>
                            <div className="col-11">
                              <textarea
                                type="text"
                                className="form-control"
                                value={success}
                                onChange={(e) => {
                                  successes[i] = e.target.value
                                  setSuccesses(_.cloneDeep(successes))
                                }}
                              />
                            </div>
                            <div className="col-1" style={{paddingLeft: 0}}>
                              <Trash style={{ cursor: 'pointer' }} onClick={() => {
                                successes.splice(i, 1)
                                setSuccesses(_.cloneDeep(successes))
                              }} />
                            </div>
                          </div>
                        })
                      }
                      <div className="row mb-1">
                        <div className="col-11" style={{ textAlign: 'right' }}>
                          <button className="btn btn-primary btn-sm" onClick={() => {
                            successes.push("")
                            setSuccesses(_.cloneDeep(successes))
                          }}>Add Accomplishment</button>
                        </div>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-12 mb-5">

                        <h3>Get your personalized raise guide</h3>
                        <p className="text-muted">We'll take your income history, role information and key accomplishments to put together a personalized raise guide giving you the best odds in getting your raise.</p>
                        {showSubmitButton ? <button className="btn btn-success" onClick={clickGetPacket} >Get Personalized Raise Guide</button> : <div className="alert alert-success" role="alert"> Your Raise Guide is on the way </div>}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>

        </div>
      </main>

      <footer class="w-100 py-4 flex-shrink-0 bg-light">
        <div class="container py-4">
          <div class="row gy-4 gx-5">
            <div class="col-lg-4 col-md-6">
              <h5 class="h1 ">Ask For A Pay Raise 💵</h5>
              <p class="small text-muted">Go into asking for a pay raise will the facts on your side</p>
              <p class="small text-muted mb-0">&copy; 2023. All rights reserved.</p>
            </div>
            <div class="col-lg-2 col-md-6">
              <h5 class=" mb-3">Quick links</h5>
              <ul class="list-unstyled text-muted">
                <li><a href="#">Home</a></li>
                <li><a href="/articles">Articles</a></li>
                <li><a href="/articles/about/">About</a></li>
                <li><a href="/articles/about/">Contact</a></li>
              </ul>
            </div>
            <div class="col-lg-2 col-md-6">
              <h5 class=" mb-3">Articles</h5>
              <ul class="list-unstyled text-muted">
                <li><a href="/articles/inflation/2023/12/09/purchasing-power.html">What is inflation</a></li>
                <li><a href="/articles/inflation/2023/12/08/inflation.html">What is purchasing power</a></li>
              </ul>
            </div>
            {/* <div class="col-lg-4 col-md-6">
              <h5 class=" mb-3">Newsletter</h5>
              <p class="small text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
              <form action="#">
            
              </form>
            </div> */}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
