import { useState, useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import { QuestionCircle } from 'react-bootstrap-icons';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

function ToolTip({text}) {
  const placement = 'right';

  return (
    <>
        <OverlayTrigger
          key={placement}
          placement={placement}
          overlay={
            <Tooltip id={`tooltip-${placement}`}>
              {text}
            </Tooltip>
          }
        >
          <QuestionCircle   />
        </OverlayTrigger>

    </>
  );
}

export default ToolTip;